import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import VueGtag from 'vue-gtag';

const app = createApp(App);

app.use(router);

// Configure VueGtag
app.use(VueGtag, {
    config: { id: 'G-RRRNZCHCC8'},
    router
});

app.mount('#app')