<script>
export default {
  props: {
    title: String,
    share: String,
    poster: String,
    show: Boolean
  },
  methods: {
    copyBoard(share) {
      // Use share interface if it's available and you're on mobile (~ < 1000px screen width)
      if (navigator.share && window.innerWidth < 1000) {
        navigator.share({
          text: share
        })
      } else {
        navigator.clipboard.writeText(share);
        document.getElementById("copy-notif").innerHTML = 'Copied to clipboard!';

        setTimeout(function(){
            document.getElementById("copy-notif").innerHTML = '';
        }, 1000);
      }
    }
  }
}
</script>

<template>
  <Transition name="PlotModal">
    <div v-if="show" class="modal-mask">
      <div class="modal-wrapper" @click="$emit('close')">
        <div class="modal-container-loss" @click.stop="">
          <div class="modal-header">
            <slot name="header"/>
          </div>

          <div class="modal-body">
            <slot name="body">
                Today's movie was <b>{{ title }}</b>.
                <img style="padding-top: 15px" width="200" :src="poster"/><br>
                <div style="padding: 10px 0">Play again tomorrow! New Oscardle available daily at 1am ET.</div>
            </slot>
            <div id="copy-notif" style="font-size: 12px"/>
          </div>
          <div class="modal-button">
            <button class="modal-share-button" @click="copyBoard(share)">
              Share
              <img src="@/assets/export-variant.png" style="margin-bottom: -2px; margin-left: 2px" width="15"/>
            </button>
            <button class="modal-default-button" @click="$emit('close')">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<style>
.modal-mask {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container-loss {
  width: min(300px, 90%);
  margin: 0px auto;
  padding: 10px 10px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #dfca4e;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header h3 {
  margin: 5px 0;
  color: #000;
  text-align: center;
}

.modal-body {
  text-align: center;
}

.modal-button {
  margin: 5px 0 15px;
  padding: 5px 0 15px;
}

.modal-share-button {
  float: left;
  width: 90px;
  padding: 8px;
  background-color: #00a700;
  border-radius: 12px;
  color: #FFF;
  font-size: 12px;
  text-transform: uppercase;
  transition: 0.4s;
  border: none;
  outline: none;
}

.modal-default-button {
  float: right;
  width: 80px;
  padding: 8px;
  background-color: #dfca4e;
  border-radius: 12px;
  color: #FFF;
  font-size: 12px;
  text-transform: uppercase;
  transition: 0.4s;
  border: none;
  outline: none;
}

.modal-default-button:hover {
  background-color: #e8d980;
  cursor: pointer;
}

.modal-share-button:hover {
  background-color: #00ce00;
  cursor: pointer;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container-loss,
.modal-leave-to .modal-container-loss {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>