<script>
export default {
  props: {
    show: Boolean
  }
}
</script>

<template>
  <Transition name="InstructionsModal">
    <div v-if="show" class="modal-mask">
      <div class="modal-wrapper" @click="$emit('close')">
        <div class="modal-container" @click.stop="">
          <div class="modal-header">
            <slot name="header"/>
          </div>

          <div class="modal-body-instructions">
            <slot name="body">
            <ul style="margin-left: -25px">
                <li>Guess the movie in 8 tries or less!</li>
                <li style="margin-bottom: 10px">Movies come from a list of 250+ popular movies from the last 50 years</li>
                <li><span style="background-color: #00a700">Green</span> in any column represents a match!</li>
                <li><span style="background-color: #ffea00">Yellow</span> in the <b>Year</b> column means today's 
                    movie was released within 2 years of your guess.</li>
                <li><span style="background-color: #ffea00">Yellow</span> in the <b>Genres</b> or <b>Actors</b> columns means today's movie shares at
                    least one of the listed genres or actors with today's movie.</li>
                <li><span style="background-color: #ffea00">Yellow</span> in the <b>Oscars</b> column with a <i>win</i> count means today's movie has won
                    at least one Oscar, but a different amount than your guess.</li>
                <li><span style="background-color: #ffea00">Yellow</span> in the <b>Oscars</b> column with a <i>nom</i> count means today's movie has been
                    nominated for at least one Oscar, but a different amount than your guess.</li>
                <li style="margin-bottom: 10px"><span style="background-color: #ffea00">Yellow</span> in the <b>&#127813; (%)</b> column means that today's movie had a Rotten
                    Tomatoes score within 5% your guess.</li>
                <li>If you're stuck, <b>view the plot</b> to get a hint!</li>
                <li>A new Oscardle is available daily at 1am ET.</li>
                <li>Source: all movie data is obtained from <a href="https://omdbapi.com/" target="_blank">omdbAPI</a>.</li>
            </ul>  
            </slot>
          </div>
          <div class="modal-button">
            <button class="modal-default-button" @click="$emit('close')">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<style>
.modal-mask {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: min(85%, 400px);
  margin: 0px auto;
  padding: 10px 10px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #dfca4e;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header h3 {
  margin: 5px 0;
  color: #000;
  text-align: center;
}

.modal-body-instructions {
  font-size: min(3.5vw, 14px);
  text-align: left !important;
}

.modal-button {
  margin: 5px 0 15px;
  padding: 5px 0 15px;
}

.modal-default-button {
  float: right;
  width: 80px;
  padding: 8px;
  background-color: #dfca4e;
  border-radius: 12px;
  color: #FFF;
  font-size: 12px;
  text-transform: uppercase;
  transition: 0.4s;
  border: none;
  outline: none;
}

.modal-default-button:hover {
  background-color: #e8d980;
  cursor: pointer;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>