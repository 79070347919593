<script>
export default {
  props: {
    show: Boolean
  }
}
</script>

<template>
  <Transition name="TwitterModal">
    <div v-if="show" class="modal-mask">
      <div class="modal-wrapper" @click="$emit('close')">
        <div class="modal-container-twitter" @click.stop="">
          <div class="modal-header">
            <slot name="header"/>
          </div>

          <div class="modal-body">
            <slot name="body">
            <div style="padding: 10px 0 20px">
                Follow the official account on Twitter!
            </div>
            <div style="width: 220px; text-align: center; margin: 0 auto; padding: 5px 5px 0; border: 2.5px solid #1DA1F2; border-radius: 10px; box-shadow: 1.5px 3px #1DA1F2">
                <a href="https://twitter.com/oscardlegame" target="_blank">
                    <img width="200" src="@/assets/twitter-handle.png"/>
                </a>
            </div>
            </slot>
          </div>
          <div class="modal-button">
            <button class="modal-default-button" @click="$emit('close')">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<style>
.modal-mask {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container-twitter {
  width: max(min(400px, 80%), 250px);
  margin: 0px auto;
  padding: 10px 10px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #dfca4e;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header h3 {
  margin: 5px 0;
  color: #000;
  text-align: center;
}

.modal-body {
  text-align: center;
}

.modal-button {
  margin: 10px 0 15px;
  padding: 10px 0 15px;
}

.modal-default-button {
  float: right;
  width: 80px;
  padding: 8px;
  background-color: #dfca4e;
  border-radius: 12px;
  color: #FFF;
  font-size: 12px;
  text-transform: uppercase;
  transition: 0.4s;
  border: none;
  outline: none;
}

.modal-default-button:hover {
  background-color: #e8d980;
  cursor: pointer;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container-twitter,
.modal-leave-to .modal-container-twitter {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>