<script>
export default {
  props: {
    show: Boolean
  }
}
</script>

<template>
  <Transition name="Maintenance">
    <div v-if="show" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container-maintenance" @click.stop="">
          <div class="modal-header">
            <slot name="header"/>
          </div>

          <div class="modal-body">
            <slot name="body">Site is currently under maintenance.
            Please check <a href="https://twitter.com/oscardlegame" target="_blank" style="text-decoration: none">
            @oscardlegame</a> on Twitter for updates!</slot>
          </div>

        </div>
      </div>
    </div>
  </Transition>
</template>

<style>
.modal-mask {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container-maintenance {
  width: max(min(400px, 80%), 250px);
  margin: 0px auto;
  padding: 10px 10px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #dfca4e;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header h3 {
  margin: 5px 0;
  color: #000;
  text-align: center;
}

.modal-body {
  text-align: center;
}

.modal-button {
  margin: 10px 0 15px;
  padding: 10px 0 15px;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container-maintenance,
.modal-leave-to .modal-container-maintenance {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>