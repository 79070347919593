<template>
  <div class="home">
    <header class="site-header">
      <img class="image-left" src="@/assets/help-circle.png" @click="showInstructionsModal = true" width="35"/>
      <Teleport to="body">
        <InstructionsModal :show="showInstructionsModal" @close="showInstructionsModal = false">
          <template #header>
            <h3>How to Play</h3>
          </template>
        </InstructionsModal>
      </Teleport>
      <a href="https://oscardle.com" style="text-decoration: none; color: #110e0e; margin-left: 5px"><img style="margin: 0 1px -1.5px 0" src="@/assets/oscardle.png" @click="showInstructionsModal = true" width="30"/><h1 style="display: inline">scardle</h1></a>
      <img class="image-right" src="@/assets/podium.png" @click="showStatsModal = true" width="35"/>
      <Teleport to="body">
        <StatsModal :show="showStatsModal" :shareBtn="inputDisabled" :share="shareText" :games="totalGames" :pct="winPct" :streak="currentStreak" @close="showStatsModal = false">
          <template #header>
            <h3>Stats</h3>
          </template>
        </StatsModal>
      </Teleport>
      <Teleport to="body">
        <Maintenance :show="showMaintenance">
          <template #header>
            <h3>Under Maintenance</h3>
          </template>
        </Maintenance>
      </Teleport>
      <Teleport to="body">
        <TwitterModal :show="showTwitterModal" @close="showTwitterModal = false">
          <template #header>
            <h3>Enjoying Oscardle?</h3>
          </template>
        </TwitterModal>
      </Teleport>
    </header>
    <h4 class="subtitle">Movie Guessing Game</h4>
    <div class="game-search">
    <form netlify hidden/>
      <input
        :disabled="inputDisabled"
        type="text"
        id="search"
        :placeholder="inputDisabled ? 'Play again tomorrow!' : 'Enter a movie title'"
        v-model="searchedMovie"
      >
      <ul v-if="searchMovies.length" class="autocomplete-results">
        <li
          v-for="movie in searchMovies"
          :key="movie.name"
          @click="selectMovie(movie.name)"
          class="autocomplete-result"
        >
          {{ movie.name }} ({{ movie.year }})
        </li>
      </ul>
    </div>
    <div style="z-index: 0; position: absolute; text-align: center; left: 5px; right: 5px; margin-left: auto; margin-right: auto; top: 155px">
      <div style="display: inline-block">
        <p v-if="!inputDisabled" class="plot-text" @click="openPlot()">
          Stuck? Click here to view the plot
        </p>
        <p v-else class="plot-text" @click="guesses[guesses.length - 1].Title == todaysData.Title ? showWinModal = true : showGameOverModal = true">
          View today's result
        </p>
      </div>
      <Teleport to="body">
        <PlotModal :show="showPlotModal" :plot="todaysData.Plot" @close="showPlotModal = false">
          <template #header>
            <h3 v-if="todaysData.Plot == undefined">Plot Hidden</h3>
            <h3 v-else>Plot</h3>
          </template>
        </PlotModal>
      </Teleport>
      <div id="game-table-id">
        <table class="game-table">
          <tr>
            <th>Title</th>
            <th>Year</th>
            <th>Rating</th>
            <th>Genres</th>
            <th>Actors</th>
            <th>Oscars</th>
            <th>&#127813; (%)</th>
          </tr>
          <tr class="guess-table" v-for="guess in guesses" :key="guess.imdbID">
            <td :style="{backgroundColor: guess.Title == todaysData.Title ? '#00a700' : '#ececec'}">
              <b>{{ guess.Title }}</b>
            </td>
            <td :style="{backgroundColor: guess.Year == todaysData.Year ? '#00a700' : ((guess.Year <= todaysData.YearHigh && guess.Year >= todaysData.YearLow) ? '#ffea00' : '#ececec')}">
              {{ guess.Year }}
              <img v-if="guess.Year < todaysData.Year" src="@/assets/arrow-up.png" width="10"/>
              <img v-if="guess.Year > todaysData.Year" src="@/assets/arrow-down.png" width="10"/>
            </td>
            <td :style="{backgroundColor: guess.Rating == todaysData.Rating ? '#00a700' : '#ececec'}">
              {{ guess.Rating }}
            </td>
            <td :style="{backgroundColor: guess.Genres == todaysData.Genres ? '#00a700' : (guess.Genres.split(', ').some(genre => todaysData.Genres.split(', ').includes(genre)) ? '#ffea00' : '#ececec')}">
              {{ guess.Genres }}
            </td>
            <td :style="{backgroundColor: guess.Actors == todaysData.Actors ? '#00a700' : (guess.Actors.split(', ').some(actor => todaysData.Actors.split(', ').includes(actor)) ? '#ffea00' : '#ececec')}">
              {{ guess.Actors }}
            </td>
            <td :style="{backgroundColor: guess.Oscars == todaysData.Oscars ? '#00a700' : ((guess.Oscars.includes('win') && todaysData.Oscars.includes('win')) || (guess.Oscars.includes('nom') && todaysData.Oscars.includes('nom')) ? '#ffea00' : '#ececec')}">
              {{ guess.Oscars }}
            </td>
            <td :style="{backgroundColor: guess.RottenScore == todaysData.RottenScore ? '#00a700' : ((guess.RottenScore <= todaysData.RottenScoreHigh && guess.RottenScore >= todaysData.RottenScoreLow) ? '#ffea00' : '#ececec')}">
              {{ guess.RottenScore }}
              <img v-if="guess.RottenScore < todaysData.RottenScore" src="@/assets/arrow-up.png" width="10"/>
              <img v-if="guess.RottenScore > todaysData.RottenScore" src="@/assets/arrow-down.png" width="10"/>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <Teleport to="body">
      <GameOverModal :show="showGameOverModal" :share="shareText" :title="todaysData.Title" :poster="todaysData.Poster" @close="showGameOverModal = false">
        <template #header>
          <h3>Out of Guesses</h3>
        </template>
      </GameOverModal>
    </Teleport>
    <Teleport to="body">
      <WinModal :show="showWinModal" :share="shareText" :title="todaysData.Title" :guessCount="guesses.length" :poster="todaysData.Poster" @close="showWinModal = false">
        <template #header>
          <h3>Winner!</h3>
        </template>
      </WinModal>
    </Teleport>
    <footer class="site-footer">
      <h5 style="display: inline; float: left">Inspired by <a style='text-decoration: underline; color: #110e0e' href='https://www.nytimes.com/games/wordle/index.html' target='_blank'>Wordle</a> and <a style='text-decoration: underline; color: #110e0e' href='https://wardle.app/' target='_blank'>WARdle</a></h5>
      <div class="footer-logos">
        <a href="https://twitter.com/oscardlegame" target="_blank">
          <img style="padding-right: 10px" src="@/assets/twitter.png" width="35"/>
        </a>
        <a href = "mailto:oscardlegame@gmail.com">
          <img style="padding-left: 10px" src="@/assets/email.png" width="35"/>
        </a>
      </div>
    </footer>
  </div>

</template>

<script>
import { ref, computed } from 'vue';
import emailjs from '@emailjs/browser';
import env from '@/env.js';
import movies from '@/data/movies.json';
import randomMovies from '@/data/randomMovies.json';
import InstructionsModal from '@/components/InstructionsModal.vue';
import StatsModal from '@/components/StatsModal.vue';
import PlotModal from '@/components/PlotModal.vue';
import WinModal from '@/components/WinModal.vue';
import GameOverModal from '@/components/GameOverModal.vue';
import Maintenance from '@/components/Maintenance.vue';
import TwitterModal from '@/components/TwitterModal.vue';

export default {
  components: {
    InstructionsModal,
    StatsModal,
    PlotModal,
    WinModal,
    GameOverModal,
    Maintenance,
    TwitterModal
  },
  setup () {
    // Main variables
    const oscardleCount = ref(0);
    const currentGuess = ref({});
    const searchedMovie = ref("");
    const guesses = ref([]);
    const todaysData = ref({});
    const todaysTitle = ref('');

    // Display content bools
    const inputDisabled = ref(false);
    const showInstructionsModal = ref(false);
    const showStatsModal = ref(false);
    const showPlotModal = ref(false);
    const showWinModal = ref(false);
    const showGameOverModal = ref(false);
    const showMaintenance = ref(false);
    const showTwitterModal = ref(false);

    // Stats variables
    const totalGames = ref(0);
    const totalWins = ref(0);
    const winPct = ref(0);
    const currentStreak = ref(0);

    // Sharing variables
    const shareText = ref('');
    const plotUsed = ref(false);
    const plotTurn = ref(1);
    
    const getDayIndex = () => {
      let initialDate = new Date('Mon Apr 25 2022 00:00:00 GMT-0500 (Central Daylight Time)');
      let currentDate = new Date();

      let differenceInTime = currentDate.getTime() - initialDate.getTime();
      oscardleCount.value = Math.floor(differenceInTime / (1000 * 3600 * 24)) + 1; // Get new random movie every 24 hours

      console.log('oscardleCount', oscardleCount.value);
    }

    const getTodaysTitle = () => {
      // Grab the next movie from array of random movies; use modulo to avoid going out of index
      const randomMovieArray = Object.values(randomMovies).map(randMovie => randMovie.name)
      const randomMovieIndex = (oscardleCount.value - 1) % randomMovieArray.length;

      todaysTitle.value = randomMovieArray[randomMovieIndex]
    }

    const loadLocalStorage = () => {
      window.localStorage.setItem("oscardleCount", oscardleCount.value);

      // If the most recent "today's movie" in local storage is different than today's actual movie,
      // that means it's a new day -> clear most local storage values
      if (JSON.parse(window.localStorage.getItem("todaysData")) != null) {
        if (todaysTitle.value != JSON.parse(window.localStorage.getItem("todaysData")).Title || '') {
          window.localStorage.removeItem("guesses");
          window.localStorage.removeItem("inputDisabled");
          window.localStorage.removeItem("todaysData");
          window.localStorage.removeItem("shareText");
          window.localStorage.removeItem("plotUsed");
          window.localStorage.removeItem("plotTurn");
        }
      }
      
      guesses.value =
        JSON.parse(window.localStorage.getItem("guesses")) || guesses.value;
      inputDisabled.value =
        JSON.parse(window.localStorage.getItem("inputDisabled")) || inputDisabled.value;
      todaysData.value =
        JSON.parse(window.localStorage.getItem("todaysData")) || todaysData.value;
      shareText.value =
        window.localStorage.getItem("shareText") || shareText.value;
      plotUsed.value =
        JSON.parse(window.localStorage.getItem("plotUsed")) || plotUsed.value;
      plotTurn.value =
        Number(window.localStorage.getItem("plotTurn")) || plotTurn.value;
      updateStatsModal();
    }

    const getTodaysMovie = (todaysTitle) => {
      // Fetch today's movie data based on the title

      fetch(`https://www.omdbapi.com/?apikey=${env.omdbKey}&t=${todaysTitle}`)
        .then(response => response.json())
        .then(data => {
          // Handle API call error
          if (data.Response == 'False') {
            console.log('Error on OMDb API call', data.Error);
            showMaintenance.value = true;
            sendAlert(data.Error, todaysTitle);
          } else {
            todaysData.value.Title = data.Title;
            todaysData.value.Rating = data.Rated;
            todaysData.value.Genres = data.Genre;
            todaysData.value.Actors = data.Actors;
            todaysData.value.Poster = data.Poster;
            todaysData.value.Plot = data.Plot + "...";
            todaysData.value.imdbID = data.imdbID;
            todaysData.value.Ratings = data.Ratings

            // Calculate Year range (+/- 2 years)
            todaysData.value.Year = parseInt(data.Year);
            todaysData.value.YearLow = parseInt(data.Year) - 2;
            todaysData.value.YearHigh = parseInt(data.Year) + 2;

            // Grab Rotten Tomatoes score from Ratings list
            todaysData.value.RottenScore = parseInt(data.Ratings.filter(function (rating) {
              if (rating.Source == 'Rotten Tomatoes') {
                return rating.Value;
              }
            }).map(rottenRating => rottenRating.Value.replace('%', ''))[0]);
            // Calculate RottenScore range (+/- 5%)
            todaysData.value.RottenScoreLow = todaysData.value.RottenScore - 5;
            todaysData.value.RottenScoreHigh = todaysData.value.RottenScore + 5;

            // Display Oscars count
            todaysData.value.Oscars = data.Awards;
            if (todaysData.value.Oscars.includes('Won') && todaysData.value.Oscars.includes('Oscar')) {
              todaysData.value.Oscars = todaysData.value.Oscars.split(' ')[1];
              if (todaysData.value.Oscars == '1') {
                todaysData.value.Oscars += ' win';
              } else {
                todaysData.value.Oscars += ' wins';
              }
            } else if (todaysData.value.Oscars.includes('Nominated for') && todaysData.value.Oscars.includes('Oscar')) {
              todaysData.value.Oscars = todaysData.value.Oscars.split(' ')[2];
              if (todaysData.value.Oscars == '1') {
                todaysData.value.Oscars += ' nom';
              } else {
                todaysData.value.Oscars += ' noms';
              }
            } else {
              todaysData.value.Oscars = 'None';
            }
          }
        })
    }

    
    const selectMovie = (guessTitle) => {
      // If today's title is different than the one stored in today's data
      // (i.e., it's their first guess of the day), get today's new data
      if (todaysTitle.value != todaysData.value.Title) {
        getTodaysMovie(todaysTitle.value);

        setTimeout((function(){
          guessMovie(guessTitle);
        }), 800);
        searchedMovie.value = '';
      } else {
        guessMovie(guessTitle);
        searchedMovie.value = '';
      }
    }

    const guessMovie = (guessTitle) => {
      // Fetch guessed movie's data based on the title
      
      fetch(`https://www.omdbapi.com/?apikey=${env.omdbKey}&t=${guessTitle}`)
        .then(response => response.json())
        .then(data => {
          // Handle API call error
          if (data.Response == 'False') {
            console.log('Error on OMDb API call', data.Error);
            showMaintenance.value = true;
            sendAlert(data.Error, guessTitle);
          } else {
            currentGuess.value.Title = data.Title;
            currentGuess.value.Year = parseInt(data.Year);
            currentGuess.value.Rating = data.Rated;
            currentGuess.value.Genres = data.Genre;
            currentGuess.value.Actors = data.Actors;
            currentGuess.value.Oscars = data.Awards;
            currentGuess.value.RottenScore = parseInt(data.Ratings.filter(function (rating) {
              if (rating.Source == 'Rotten Tomatoes') {
                return rating.Value;
              }
            }).map(rottenRating => rottenRating.Value.replace('%', ''))[0]);
            currentGuess.value.imdbID = data.imdbID;

            // Display Oscars count
            if (currentGuess.value.Oscars.includes('Won')) {
              currentGuess.value.Oscars = currentGuess.value.Oscars.split(' ')[1];
              if (currentGuess.value.Oscars == '1') {
                currentGuess.value.Oscars += ' win';
              } else {
                currentGuess.value.Oscars += ' wins';
              }
            } else if (currentGuess.value.Oscars.includes('Nominated for')) {
              currentGuess.value.Oscars = currentGuess.value.Oscars.split(' ')[2];
              if (currentGuess.value.Oscars == '1') {
                currentGuess.value.Oscars += ' nom';
              } else {
                currentGuess.value.Oscars += ' noms';
              }
            } else {
              currentGuess.value.Oscars = 'None';
            }

            handleSearchCorrections();
            handleGuessCorrections();
            handleOscardleCorrections();

            guesses.value.push({ ...currentGuess.value });

            if (currentGuess.value.Title == todaysData.value.Title) {
              handleWin();
            } else if (guesses.value.length >= 8) {
              handleLoss();
            }
            // Save the board in case they refresh
            preserveGameState();
          }
        })
    }

    const preserveGameState = () => {
      window.localStorage.setItem("guesses", JSON.stringify(guesses.value));
      window.localStorage.setItem("todaysData", JSON.stringify(todaysData.value));
      window.localStorage.setItem("inputDisabled", inputDisabled.value);
      window.localStorage.setItem("shareText", shareText.value);
      window.localStorage.setItem("plotUsed", plotUsed.value);
      window.localStorage.setItem("plotTurn", plotTurn.value);
    }

    const handleWin = () => {
      totalWins.value = Number(window.localStorage.getItem("totalWins")) || 0;
      window.localStorage.setItem("totalWins", totalWins.value + 1);

      currentStreak.value = Number(window.localStorage.getItem("currentStreak")) || 0;
      window.localStorage.setItem("currentStreak", currentStreak.value + 1);
      
      totalGames.value = Number(window.localStorage.getItem("totalGames")) || 0;
      window.localStorage.setItem("totalGames", totalGames.value + 1);

      updateStatsModal();
      shareText.value = 'Oscardle #' + oscardleCount.value + ' - ' + guesses.value.length + '/8 ' + (plotUsed.value ? '\uD83C\uDD7F\uFE0F' + '(' + plotTurn.value + ')\n\n' : '\n\n')
      createShareText();
      inputDisabled.value = true;
      showWinModal.value = true;
    }

    const handleLoss = () => {
      window.localStorage.setItem("currentStreak", 0);
            
      totalGames.value = Number(window.localStorage.getItem("totalGames")) || 0;
      window.localStorage.setItem("totalGames", totalGames.value + 1);

      updateStatsModal();
      shareText.value = 'Oscardle #' + oscardleCount.value + ' - X/8 ' + (plotUsed.value ? '\uD83C\uDD7F\uFE0F' + '(' + plotTurn.value + ')\n\n' : '\n\n')
      createShareText();
      inputDisabled.value = true;
      showGameOverModal.value = true;
    }

    const updateStatsModal = () => {
      currentStreak.value = Number(window.localStorage.getItem("currentStreak"));
      totalWins.value = Number(window.localStorage.getItem("totalWins"));
      totalGames.value = Number(window.localStorage.getItem("totalGames"));

      winPct.value = Math.round((totalWins.value / totalGames.value) * 100) || 0;
    }

    const openPlot = () => {
      console.log(guesses.value.length)
      if (guesses.value.length > 0) {
        if (plotUsed.value == false) {
          plotTurn.value = guesses.value.length + 1;
          window.localStorage.setItem("plotTurn", plotTurn.value);
        }
        plotUsed.value = true;
        window.localStorage.setItem("plotUsed", plotUsed.value);
      }
      showPlotModal.value = true;
    }

    const createShareText = () => {
      let greenSquare = '\uD83D\uDFE9';
      let whiteSquare = '\u2B1C';
      let yellowSquare = '\uD83D\uDFE8';

      for (var guess of guesses.value) {
        // Add Title tile to row
        if (guess.Title == todaysData.value.Title) {
          shareText.value += greenSquare;
        } else {
          shareText.value += whiteSquare;
        }
        // Add Year tile to row
        if (guess.Year == todaysData.value.Year) {
          shareText.value += greenSquare;
        } else if (guess.Year <= todaysData.value.YearHigh && guess.Year >= todaysData.value.YearLow) {
          shareText.value += yellowSquare;
        } else {
          shareText.value += whiteSquare;
        }
        // Add Rating tile to row
        if (guess.Rating == todaysData.value.Rating) {
          shareText.value += greenSquare;
        } else {
          shareText.value += whiteSquare;
        }
        // Add Genres tile to row
        if (guess.Genres == todaysData.value.Genres) {
          shareText.value += greenSquare;
        } else if (guess.Genres.split(', ').some(genre => todaysData.value.Genres.split(', ').includes(genre))) {
          shareText.value += yellowSquare;
        } else {
          shareText.value += whiteSquare;
        }
        // Add Actors tile to row
        if (guess.Actors == todaysData.value.Actors) {
          shareText.value += greenSquare;
        } else if (guess.Actors.split(', ').some(actor => todaysData.value.Actors.split(', ').includes(actor))) {
          shareText.value += yellowSquare;
        } else {
          shareText.value += whiteSquare;
        }
        // Add Oscars tile to row
        if (guess.Oscars == todaysData.value.Oscars) {
          shareText.value += greenSquare;
        } else if ((guess.Oscars.includes('win') && todaysData.value.Oscars.includes('win')) || (guess.Oscars.includes('nom') && todaysData.value.Oscars.includes('nom'))) {
          shareText.value += yellowSquare;
        } else {
          shareText.value += whiteSquare;
        }
        // Add Rotten Tomatoes tile to row
        if (guess.RottenScore == todaysData.value.RottenScore) {
          shareText.value += greenSquare;
        } else if (guess.RottenScore <= todaysData.value.RottenScoreHigh && guess.RottenScore >= todaysData.value.RottenScoreLow) {
          shareText.value += yellowSquare;
        } else {
          shareText.value += whiteSquare;
        }
        shareText.value += '\n'
      }
      shareText.value += 'https://oscardle.com'
    }

    const searchMovies = computed(() => {
      if (searchedMovie.value === '') {
        return []
      }

      let matches = 0

      return movies.filter(movie => {
        if (
          movie.name.toLowerCase().includes(searchedMovie.value.toLowerCase())
          && matches < 10
        ) {
          matches++
          return movie
        }
      })
    });

    const handleSearchCorrections = () => {
      // Copy code from ReadMe -> CODE TO HANDLE SEARCH CORRECTIONS
      if (currentGuess.value.Title == 'The Incredibles') { 
        currentGuess.value.RottenScore = 97;
      }
      if (currentGuess.value.Title == 'Booksmart') { 
        currentGuess.value.Oscars = 'None';
      }
    }

    const handleGuessCorrections = () => {
      // ----- UNCOMMENT these when first correction is needed -----

      const guessIndices = []; // Stores indices in guesses.value for which a specific title was guessed
      const guessTitles = guesses.value.map(guess => guess.Title);

      // Copy code from ReadMe -> CODE TO HANDLE GUESS CORRECTIONS
      // One overall block per movie! But can fix multiple params for a single movie at the same time
      for(var i = 0; i < guessTitles.length; i++) {
        if (guessTitles[i] === 'The Incredibles') {
          guessIndices.push(i);
        }

        if (guessIndices.length > 0) {
          for (var index of guessIndices) {
            guesses.value[index].RottenScore = 97;
          }
        }
      }

      guessIndices.length = 0;
      for(var i = 0; i < guessTitles.length; i++) {
        if (guessTitles[i] === 'Booksmart') {
          guessIndices.push(i);
        }

        if (guessIndices.length > 0) {
          for (var index of guessIndices) {
            guesses.value[index].Oscars = 'None';
          }
        }
      }
    }

    const handleOscardleCorrections = () => {
      // Copy code from ReadMe -> CODE TO HANDLE OSCARDLE CORRECTIONS
      if (todaysData.value.Title == 'The Incredibles') {
        todaysData.value.RottenScore = 97;
        todaysData.value.RottenScoreHigh = 99;
        todaysData.value.RottenScoreLow = 95;
      }

      if (todaysData.value.Title == 'Booksmart') {
        todaysData.value.Oscars = 'None';
      }
    }

    // Send alerts when site is throwing API errors
    const sendAlert = (errorMessage, searchMovie) => {
      var templateParams = {
        error_message: errorMessage,
        search_movie: searchMovie
      };
      emailjs.send('service_iwzmynj', 'template_4mq0ov6', templateParams, env.emailJSKey)
        .then(function(response) {
          console.log('Developers have been notified', response.status, response.text);
        }, function(error) {
          console.log(error);
        });
    }

    // -------------------------------------------
    // ---------- START Page Load Steps ----------
    // -------------------------------------------

    // Always get oscardleCount first
    getDayIndex();

    // If they haven't played before, show the instructions
    if (window.localStorage.getItem("totalGames") == null) {
      showInstructionsModal.value = true;
    }

    // On game #3, and every 10 games after, promote Twitter
    if (window.localStorage.getItem("totalGames") % 10 == '3') {
      showTwitterModal.value = true;
    }

    getTodaysTitle();
    loadLocalStorage();

    handleOscardleCorrections();
    handleGuessCorrections();

    // -----------------------------------------
    // ---------- END Page Load Steps ----------
    // -----------------------------------------

    return {
      // Main variables
      oscardleCount,
      currentGuess,
      searchedMovie,
      guesses,
      todaysData,
      todaysTitle,
      // Display content bools
      inputDisabled,
      showInstructionsModal,
      showStatsModal,
      showPlotModal,
      showWinModal,
      showGameOverModal,
      showMaintenance,
      showTwitterModal,
      // Stats variables
      totalGames,
      totalWins,
      currentStreak,
      winPct,
      // Sharing variables
      shareText,
      plotTurn,
      plotUsed,
      // Functions
      getDayIndex,
      getTodaysTitle,
      loadLocalStorage,
      getTodaysMovie,
      selectMovie,
      guessMovie,
      preserveGameState,
      handleWin,
      handleLoss,
      updateStatsModal,
      openPlot,
      createShareText,
      searchMovies,
      handleSearchCorrections,
      handleGuessCorrections,
      handleOscardleCorrections,
      sendAlert,
      // Imports
      randomMovies,
      movies,
      InstructionsModal,
      StatsModal,
      PlotModal,
      WinModal,
      GameOverModal,
      Maintenance
    }
  }
}
</script>

<style lang="scss">
.home {
  overflow: hidden;
  .site-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    margin: 0;
    padding: 15px 15px 5px 10px;
    background-color: #dfca4e;
  }

  .site-footer {
    position: fixed;
    width: 100%;
    bottom: 0;
    margin: 0px -10px;
    padding: 0px 15px;
    background-color: #dfca4e;
  }

  .footer-logos {
    margin-top: 15px;
    margin-right: 25px;
    display: inline;
    float: right;
  }

  .image-left {
    display: inline;
    float: left;
  }

  .image-left:hover {
    cursor: pointer;
  }

  .image-right {
    margin: 0px 25px 10px 0px;
    display: inline;
    float: right;
  }

  .image-right:hover {
    cursor: pointer;
  }

  .subtitle {
    font-size: 16px;
    margin-top: 75px
  }

  .game-table {
    margin-top: 10px;
    font-size: min(2.5vw, 16px);
    width: 100%;
    border-collapse: collapse;

    td {
      font-size: min(2vw, 14px);
      padding: 2px;
    }
  }


  .plot-text {
    font-size: 13px;
    padding: 6px 0 0;
    text-decoration: underline
  }

  .plot-text:hover {
    cursor: pointer;
  }

  .game-search {
    position: relative;
    z-index: 900;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 5px;

    input {
      &[type="text"] {
        -webkit-appearance: none;
        width: min(80%, 600px);
        color: #000;
        font-size: 12px;
        padding: 10px 16px;
        border-radius: 8px;
        margin: 0px 0px 1px;
        transition: 0.4s;
        border: 0.5px solid #c2c2c2;
        outline: none;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);

        &::placeholder {
          color: #8f8f8f;
        }

        &:focus {
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
        }
      }
    }

    .autocomplete-results {
      z-index: 900;
      width: min(80%, 600px);
      background-color: #dfca4e;
      color: black;
      font-size: 12px;
      padding: 0 17px;
      margin: auto;
      border: 1px solid #eeeeee;
      border-radius: 8px;
      transition: 0.4s;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
    }

    .autocomplete-result {
      z-index: 900;
      list-style: none;
      margin: 0 -16px;
      padding: 6px 2px;
      cursor: pointer;
    }

    .autocomplete-result:hover {
      background-color: #b2a13e;
      color: white;
      border-radius: 8px;
    }

    .autocomplete-result:active {
      background-color: #b2a13e;
      color: white;
      border-radius: 8px;
    }
  }
}
</style>